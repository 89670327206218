<template>
	<div class="kpipanel kpipanel-content mb-3">
		<template v-for="(item, index) in items">
			<div :key="index">
				<slot v-bind="{ item, percents }" />
			</div>
		</template>
	</div>
</template>
<script>
const SAFE_PERCENT = 2.5;

export default {
	name: 'KpiPanel',

	props: {
		items: {
			type: Array,
			required: true
		},

		totalViewport: {
			type: Number,
			default: 0
		}
	},

	computed: {
		percents() {
			return this.items.reduce((object, { id, bars, total }) => {
				console.log('dev hlp reduce', object, id, bars, total);
				if (!bars) {
					object[id] = this.getPercent(total);
					return object;
				} else {
					const kv = bars.reduce((o, item) => ({ ...o, [item.id]: item.total }), {});
					object = { ...object, ...this.getPercents(kv) };
					return object;
				}
			}, {});
		}
	},
	mounted() {
		console.log('dev hlp percents', this.items, this.percents, this.totalViewport);
	},

	methods: {
		getPercent(total) {
			if (this.totalViewport === 0 || total === 0) {
				return 0;
			}

			const value = (total * 100) / this.totalViewport;

			return value > SAFE_PERCENT ? value : SAFE_PERCENT;
		},

		getPercents(object) {
			const toSum = (a, b) => a + b;

			const sum = Object.values(object).reduce(toSum);
			const percentsTotal = Object.values(object)
				.map((value) => this.getPercent(value))
				.reduce(toSum);

			const percents = Object.entries(object).reduce((o, [k, v]) => ({ ...o, [k]: this.getPercent(v) }), {});

			if (percentsTotal <= 100) {
				return percents;
			}

			const numProperPercent = Object.values(percents).filter((value) => value > SAFE_PERCENT);
			const removePercent = sum - 100;
			const removePercentPerItem = removePercent / numProperPercent;

			return Object.entries(percents).reduce((o, [k, v]) => ({ ...o, [k]: v > SAFE_PERCENT ? v - removePercentPerItem : v }), {});
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.kpibar {
	height: 6px;
	width: 100%;
	background: #e4e4e4;
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}

.kpipanel {
	width: 100%;
	font-size: 12px;
	font-weight: 700;

	& .tip {
		height: 20px;
		width: 30px;
		background: red !important;
	}
	& .detail {
		& .groupdetaillines {
			margin-top: 5px;
		}
	}
}
</style>
