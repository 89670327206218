export default {
	computed: {
		isLayerFiltered() {
			if (this.$store.getters.notificationsLayer(this.userProperties.organizationid)) {
				const notifications = this.$store.getters.notificationsLayer(this.userProperties.organizationid);

				return [
					// eslint-disable-next-line
					notifications.filtered,
					!notifications.active
				].some(Boolean);
			}
			return false;
		}
	}
};
