import KpisMixin from './KpisMixin';

import store from '@/store/store';

export default {
	mixins: [KpisMixin],

	data() {
		return {
			expanded: {}
		};
	},

	computed: {
		severityKeys() {
			return store.getters.getSeverities.map((obj) => obj.acronym);
		},
		kpis_byArea() {
			return this.notificationsKpis_byArea;
		},

		kpis_byDatasourcetype() {
			return this.notificationsKpis_byDatasourcetype;
		},

		totalViewport_byArea() {
			return this.$store.getters.totalNotifications_byArea.viewport;
		},

		totalViewport_byDatasourcetype() {
			return this.$store.getters.totalNotifications_byDatasourcetype.viewport;
		},

		isDisabled_byArea() {
			const selectedKpi = this.$store.getters.notificationsKpiSelected_byArea;
			const disabled = (list) => selectedKpi !== null && list.every((kpi) => selectedKpi !== kpi);

			var activeKeys = this.getActiveKeys_byArea();

			return Object.entries(activeKeys).reduce((o, [k, { keys, others }]) => ({ ...o, [k]: disabled([...keys, ...others]) }), {});
		},

		isDisabled_byDatasourcetype() {
			const selectedKpi = this.$store.getters.notificationsKpiSelected_byDatasourcetype;
			const disabled = (list) => selectedKpi !== null && list.every((kpi) => selectedKpi !== kpi);

			var activeKeys = this.getActiveKeys_byDatasourcetype();

			return Object.entries(activeKeys).reduce((o, [k, { keys, others }]) => ({ ...o, [k]: disabled([...keys, ...others]) }), {});
		},

		totals_byArea() {
			const sum = (keys) => keys.reduce((acc, key) => acc + this.kpis_byArea[key], 0);

			var activeKeys = this.getActiveKeys_byArea();

			return Object.entries(activeKeys).reduce((o, [k, { keys }]) => ({ ...o, [k]: sum(keys) }), {});
		},

		totals_byDatasourcetype() {
			const sum = (keys) => keys.reduce((acc, key) => acc + this.kpis_byDatasourcetype[key], 0);

			var activeKeys = this.getActiveKeys_byDatasourcetype();

			return Object.entries(activeKeys).reduce((o, [k, { keys }]) => ({ ...o, [k]: sum(keys) }), {});
		},

		applieds_byArea() {
			const selectedKpi = this.$store.getters.notificationsKpiSelected_byArea;
			var area_properties = this.array_Areas();

			var activeKeys = this.getActiveKeys_byArea();

			return area_properties.reduce((o, k) => ({ ...o, [k]: activeKeys[k].keys[0] === selectedKpi }), {});
		},

		applieds_byDatasourcetype() {
			const selectedKpi = this.$store.getters.notificationsKpiSelected_byDatasourcetype;
			var datasource_properties = this.array_Datasourcetype();

			var activeKeys = this.getActiveKeys_byDatasourcetype();

			return datasource_properties.reduce((o, k) => ({ ...o, [k]: activeKeys[k].keys[0] === selectedKpi }), {});
		},

		filterMethod_byArea() {
			var activeKeys = this.getActiveKeys_byArea();

			return Object.entries(activeKeys).reduce((o, [k, { keys }]) => ({ ...o, [k]: () => this.filterNotifications_byArea(...keys) }), {});
		},

		filterMethod_byDatasourcetype() {
			var activeKeys = this.getActiveKeys_byDatasourcetype();

			return Object.entries(activeKeys).reduce(
				(o, [k, { keys }]) => ({ ...o, [k]: () => this.filterNotifications_byDatasourcetype(...keys) }),
				{}
			);
		},

		titles_byArea() {
			var areas = store.getters.getAreas;
			let severities = store.getters.getSeverities;
			const titlesbyArea = {};
			var count = 0;

			areas.forEach((item) => {
				//console.log(item);
				//count += 1;
				count = item.pmareaid;
				titlesbyArea['area_' + count] = item.name;

				severities.forEach((severity) => {
					titlesbyArea[severity.acronym + count] = severity.name;
				});
				//titlesbyArea['ok' + count] = this.$t('status.ok');
				//titlesbyArea['WARN' + count] = this.$t('status.warning');
				//titlesbyArea['ALARM' + count] = this.$t('status.alerm');
			});

			return titlesbyArea;
		},

		titles_byDatasourcetype() {
			var datasources = store.getters.getDatasourcetype;
			let severities = store.getters.getSeverities;
			const titlesbyDatasourcetype = {};
			var count = 0;

			datasources.forEach((item) => {
				//console.log(item);
				//count += 1;
				count = item.pmdatasourcetypeid;
				titlesbyDatasourcetype['datasourcetype_' + count] = item.name;

				severities.forEach((severity) => {
					titlesbyDatasourcetype[severity.acronym + count] = severity.name;
				});
				//titlesbyDatasourcetype['ok' + count] = this.$t('status.ok');
				//titlesbyDatasourcetype['WARN' + count] = this.$t('status.warning');
				//titlesbyDatasourcetype['ALARM' + count] = this.$t('status.alerm');
			});

			return titlesbyDatasourcetype;
		},

		colors_byDatasourcetype() {
			var datasources = store.getters.getDatasourcetype;
			let severities = store.getters.getSeverities;
			const colorsbyDatasourcetype = {};
			var count = 0;
			// eslint-disable-next-line no-unused-vars
			datasources.forEach((item) => {
				//console.log(item);
				//count += 1;
				count = item.pmdatasourcetypeid;
				severities.forEach((severity) => {
					colorsbyDatasourcetype[severity.acronym + count] = severity.color;
				});
				//colorsbyDatasourcetype['ok' + count] = 'green';
				//colorsbyDatasourcetype['WARN' + count] = 'orange';
				//colorsbyDatasourcetype['ALARM' + count] = 'red';
			});

			return colorsbyDatasourcetype;
		},

		colors_byArea() {
			var areas = store.getters.getAreas;
			let severities = store.getters.getSeverities;
			const colorsbyArea = {};
			var count = 0;
			// eslint-disable-next-line no-unused-vars
			areas.forEach((item) => {
				//console.log(item);
				//count += 1;
				count = item.pmareaid;
				severities.forEach((severity) => {
					colorsbyArea[severity.acronym + count] = severity.color;
				});
				//colorsbyArea['ok' + count] = 'green';
				//colorsbyArea['WARN' + count] = 'orange';
				//colorsbyArea['ALARM' + count] = 'red';
			});
			console.log('colors area', colorsbyArea);
			return colorsbyArea;
		},

		exclamations_byArea() {
			//return ['WARN', 'ALARM'].reduce((o, k) => ({ ...o, [k]: this.totals_byArea[k] > 0 }), {}); severityKeys
			return this.severityKeys.reduce((o, k) => ({ ...o, [k]: this.totals_byArea[k] > 0 }), {});
		},

		exclamations_byDatasourcetype() {
			//return ['WARN', 'ALARM'].reduce((o, k) => ({ ...o, [k]: this.totals_byDatasourcetype[k] > 0 }), {});
			return this.severityKeys.reduce((o, k) => ({ ...o, [k]: this.totals_byDatasourcetype[k] > 0 }), {});
		},

		closeButtons_byArea() {
			var area_properties = this.array_Areas();

			return area_properties.reduce((o, k) => ({ ...o, [k]: this.applieds_byArea[k] && this.totals_byArea[k] === 0 }), {});
		},

		closeButtons_byDatasourcetype() {
			var datasource_properties = this.array_Datasourcetype();

			return datasource_properties.reduce(
				(o, k) => ({ ...o, [k]: this.applieds_byDatasourcetype[k] && this.totals_byDatasourcetype[k] === 0 }),
				{}
			);
		},

		items_byArea() {
			const toProperties = ({ id, bars }) => ({
				id,
				total: this.totals_byArea[id] || 0,
				bars: bars ? bars.map(toProperties) : bars
			});

			return this.getHeader_byArea().map(toProperties);
		},

		items_byDatasourcetype() {
			const toProperties = ({ id, bars }) => ({
				id,
				total: this.totals_byDatasourcetype[id] || 0,
				bars: bars ? bars.map(toProperties) : bars
			});

			return this.getHeader_byDatasourcetype().map(toProperties);
		}
	},

	methods: {
		filterNotifications_byArea(...kpis) {
			const selectedKpi = this.$store.getters.notificationsKpiSelected_byArea;
			const kpi = kpis.reduce((a, b) => a + b);
			const isSelected = !selectedKpi || selectedKpi !== kpi;

			const key = isSelected ? kpi : null;
			const status = isSelected ? kpis : [];

			this.$store.commit('setNotificationsKpiSelected_byArea', { key, status });
		},

		filterNotifications_byDatasourcetype(...kpis) {
			const selectedKpi = this.$store.getters.notificationsKpiSelected_byDatasourcetype;
			const kpi = kpis.reduce((a, b) => a + b);
			const isSelected = !selectedKpi || selectedKpi !== kpi;

			const key = isSelected ? kpi : null;
			const status = isSelected ? kpis : [];

			this.$store.commit('setNotificationsKpiSelected_byDatasourcetype', { key, status });
		},

		clearFilter_byArea() {
			this.$store.commit('setNotificationsKpiSelected_byArea', { key: null, status: [] });
		},

		clearFilter_byDatasourcetype() {
			this.$store.commit('setNotificationsKpiSelected_byDatasourcetype', { key: null, status: [] });
		},

		array_Areas() {
			var areas = store.getters.getAreas;
			var area_properties = [];
			var count = 0;
			// eslint-disable-next-line no-unused-vars
			areas.forEach((item) => {
				//count += 1;
				count = item.pmareaid;
				area_properties.push('area_' + count);
			});
			return area_properties;
		},

		array_Datasourcetype() {
			var datasources = store.getters.getDatasourcetype;
			var datasource_properties = [];
			var count = 0;
			// eslint-disable-next-line no-unused-vars
			datasources.forEach((item) => {
				//count += 1;
				count = item.pmdatasourcetypeid;
				datasource_properties.push('datasourcetype_' + count);
			});
			return datasource_properties;
		},

		getHeader_byArea() {
			var areas = store.getters.getAreas;
			let severities = store.getters.getSeverities;

			var count = 0;
			var header = [];

			var properties = {};

			// eslint-disable-next-line no-unused-vars
			areas.forEach((item) => {
				//count += 1;
				count = item.pmareaid;
				properties = {
					id: 'area_' + count,
					bars: [
						/* {
							id: 'ok' + count
						},
						{
							id: 'WARN' + count
						},
						{
							id: 'ALARM' + count
						} */
					]
				};
				let dynamicBars = [];
				severities.forEach((severity) => {
					dynamicBars.push({ id: severity.acronym + count });
				});
				properties.bars = dynamicBars;
				header.push(properties);
			});
			return header;
		},
		getHeader_byAreaType(areaTypeId) {
			var areas = store.getters.getAreas.filter((area) => area.pmareatypeid == areaTypeId);
			let severities = store.getters.getSeverities;

			var count = 0;
			var header = [];

			var properties = {};
			if (areas) {
				areas.forEach((item) => {
					//count += 1;
					count = item.pmareaid;
					properties = {
						id: 'area_' + count,
						bars: [
							/* {
								id: 'ok' + count
							},
							{
								id: 'WARN' + count
							},
							{
								id: 'ALARM' + count
							} */
						]
					};
					let dynamicBars = [];
					severities.forEach((severity) => {
						dynamicBars.push({ id: severity.acronym + count });
					});
					properties.bars = dynamicBars;
					header.push(properties);
				});
			}

			return header;
		},

		getHeader_byDatasourcetype() {
			var datasources = store.getters.getDatasourcetype;
			let severities = store.getters.getSeverities;

			var count = 0;
			var header = [];

			var properties = {};

			// eslint-disable-next-line no-unused-vars
			datasources.forEach((item) => {
				//count += 1;
				count = item.pmdatasourcetypeid;
				let dynamicBars = [];
				properties = {
					id: 'datasourcetype_' + count,
					bars: [
						/* {
							id: 'ok' + count
						},
						{
							id: 'WARN' + count
						},
						{
							id: 'ALARM' + count
						} */
					]
				};
				severities.forEach((severity) => {
					dynamicBars.push({ id: severity.acronym + count });
				});
				properties.bars = dynamicBars;
				header.push(properties);
			});
			return header;
		},

		getActiveKeys_byArea() {
			var activeKeys = {};

			var areas = store.getters.getAreas;
			let severities = store.getters.getSeverities;
			var count = 0;
			// eslint-disable-next-line no-unused-vars
			areas.forEach((item) => {
				count = item.pmareaid;
				//count += 1;

				activeKeys['area_' + count] = {
					keys: [
						/* 'ok' + count,  'WARN' + count, 'ALARM' + count */
					],
					others: []
				};

				/* activeKeys['ok' + count] = {
					keys: ['ok' + count],
					others: []
				} */
				severities.forEach((severity) => {
					activeKeys['area_' + count]['keys'].push(severity.acronym + count);
					activeKeys[severity.acronym + count] = {
						keys: [severity.acronym + count],
						others: []
					};
				});
				/* activeKeys['WARN' + count] = {
					keys: ['WARN' + count],
					others: []
				}

				activeKeys['ALARM' + count] = {
					keys: ['ALARM' + count],
					others: []
				} */
			});

			return activeKeys;
		},

		getActiveKeys_byDatasourcetype() {
			var activeKeys = {};

			var datasources = store.getters.getDatasourcetype;
			let severities = store.getters.getSeverities;
			var count = 0;
			// eslint-disable-next-line no-unused-vars
			datasources.forEach((item) => {
				//count += 1;
				count = item.pmdatasourcetypeid;

				activeKeys['datasourcetype_' + count] = {
					keys: [
						/* 'ok' + count,  'WARN' + count, 'ALARM' + count */
					],
					others: []
				};
				severities.forEach((severity) => {
					activeKeys['datasourcetype_' + count]['keys'].push(severity.acronym + count);
					activeKeys[severity.acronym + count] = {
						keys: [severity.acronym + count],
						others: []
					};
				});
				/* activeKeys['ok' + count] = {
					keys: ['ok' + count],
					others: []
				}

				activeKeys['WARN' + count] = {
					keys: ['WARN' + count],
					others: []
				}

				activeKeys['ALARM' + count] = {
					keys: ['ALARM' + count],
					others: []
				}*/
			});

			return activeKeys;
		},
		items_byAreaType(areaTypeId) {
			const toProperties = ({ id, bars }) => ({
				id,
				total: this.totals_byArea[id] || 0,
				bars: bars ? bars.map(toProperties) : bars
			});

			return this.getHeader_byAreaType(areaTypeId).map(toProperties);
		}
	}
};
