<template>
	<div class="header d-flex flex-nowrap justify-start align-start width-100 pl-0">
		<v-btn @click="click" icon small :ripple="false" plain color="var(--high) !important">
			<v-icon size="14" class="mt-4" :class="{ rotated: value }">
				far
				{{ value ? 'fa-minus' : 'fa-plus' }}
			</v-icon>
		</v-btn>
		<div class="width-100">
			<v-hover v-slot="{ hover }">
				<v-card
					class="width-100 pa-1"
					@click="centerAreaMap(areaId)"
					:title="$t('panels.notifications.clickToCenterArea')"
					:color="hover ? 'var(--azul-primary-select)' : 'white'"
					flat
				>
					<div class="header-title fs-regular d-flex align-center mt-2" :class="{ disabled }" style="width: 100%; height: 20px">
						<div class="flex-grow-1">
							{{ title }}
						</div>
						<div class="ml-auto">{{ total }}</div>
					</div>

					<div class="kpibar d-flex justify-start" style="border-radius: 2px">
						<slot />
					</div>
				</v-card>
			</v-hover>
			<v-expand-transition>
				<slot name="expand" v-bind="{ value }" />
			</v-expand-transition>
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import * as olProj from 'ol/proj';
import * as olExtent from 'ol/extent';
//import debounce from 'debounce';
import { adjustNotificationsHeight } from '@/api/common';
import _debounce from 'debounce';

export default {
	props: {
		value: {},

		disabled: {
			type: Boolean,
			default: false
		},

		title: {
			type: String
		},
		areaId: {
			type: String
		},

		total: {
			type: Number
		}
	},
	data() {
		return {
			selectedAreaId: null
		};
	},
	mounted() {
		console.log('kpiexpandedheader area', this.areaId);
	},
	updated() {
		console.log('height debug updated', this.$parent.$parent.$parent.$el, this.$parent.$parent.$parent.$el.clientHeight);
	},
	methods: {
		click() {
			this.$emit('input', !this.value);
			_debounce(adjustNotificationsHeight(this.$store.state.data), 300);
		},
		centerAreaMap(areaId) {
			let areas = this.$store.getters.getAreas;
			let userProps = this.userProperties;
			let selectedArea = areas.find((area) => area.pmareaid == areaId);
			let polygon = [];
			// TODO: use areapoints array to center on area. example: [[lat,lon],[lat,lon]]
			console.log('NEW Structure: Area points', JSON.parse(selectedArea.areapoints), selectedArea);
			if (areaId != this.selectedAreaId) {
				if (selectedArea && selectedArea.nelat) {
					polygon = [
						[selectedArea.nelat, selectedArea.nelon],
						[selectedArea.swlat, selectedArea.nelon],
						[selectedArea.swlat, selectedArea.swlon],
						[selectedArea.nelat, selectedArea.swlon]
					];
				} else {
					polygon = [
						[userProps.nelat, userProps.nelon],
						[userProps.swlat, userProps.nelon],
						[userProps.swlat, userProps.swlon],
						[userProps.nelat, userProps.swlon]
					];
					this.$puiNotify.warning(this.$t('map.areaNotFound'), this.$t('status.warning'));
				}
				this.selectedAreaId = areaId;
			} else {
				polygon = [
					[userProps.nelat, userProps.nelon],
					[userProps.swlat, userProps.nelon],
					[userProps.swlat, userProps.swlon],
					[userProps.nelat, userProps.swlon]
				];
				this.$puiNotify.info(this.$t('map.zoomReset'), this.$t('status.info'));
				this.selectedAreaId = null;
			}

			console.log('selected area expanded', areaId, this.selectedAreaId, selectedArea);
			ol2map.zoomToExtent(olProj.transformExtent(new olExtent.boundingExtent(polygon), 'EPSG:4326', 'EPSG:3857'));
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.header {
	color: var(--high);

	& .icon {
		transform-origin: center center;
	}

	& .rotated {
		transform: rotate(-180deg);
	}

	& .header-title {
		transition: color 0.2s ease-in !important;
		cursor: pointer;
	}

	& .disabled {
		color: var(--moderate);
	}
}

.kpibar {
	height: 6px;
	width: 100%;
	background: #e4e4e4;
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}
</style>
