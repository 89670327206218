<template>
	<div class="cardparameter d-flex px-0 py-3 align-start">
		<!-- Notifications -->
		<div v-if="isNotification" class="mx-2 pa-0" style="width: 36px">
			<div class="d-flex justify-start">
				<!-- Si queremos mostrar un icono como el tipo de parametro -->
			</div>
		</div>

		<div class="d-flex flex-column mx-2 align-center">
			<div class="cardparameter__textparameter">{{ parameterTitle }}</div>
			<!-- <span v-if="feature['is_average'] == 1">AVG</span> -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'cardparameter',

	props: {
		feature: {
			type: Object,
			required: true
		},

		type: {
			type: String,
			required: true
		}
	},

	computed: {
		isNotification() {
			//return this.type === 'notification';
			return false;
		},

		parameterTitle() {
			// devolver el texto del estado de la feature
			if (!this.feature) {
				return '--';
			}

			const { parameter_acronym } = this.feature;
			//const { parameter_value } = this.feature;
			const { rule_name } = this.feature;

			//const parameter_detail = parameter_name + ': ' + parameter_value;
			const parameter_detail = parameter_acronym + ' - ' + rule_name;

			if (parameter_detail) {
				return parameter_detail;
			}

			return '--';
		}
	},
	mounted() {
		//console.log('debug card text: ', this.feature);
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
.cardparameter {
	& * {
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
	}
	&__textparameter {
		font-size: 1rem;
		line-height: 1.25;
		color: var(--high);
	}
	&__warn {
		font-size: 20px;
		color: var(--warning);
	}

	& i {
		font-weight: bold;
	}
}
</style>
