import LayersActiveMixin from '@/mixins/LayersActiveMixin';
import store from '@/store/store';

export default {
	mixins: [LayersActiveMixin],

	computed: {
		notificationsKpis_byArea() {
			// 1: Palma; 2: Mahon; 3: Ibiza; 4: Alcudia; 5: Savina;
			var areas = store.getters.getAreas;
			let severities = store.getters.getSeverities;
			//console.log(areas);
			console.log('KPIS MIXIN AREAS');

			const empty = {};
			var count = 0;

			areas.forEach((item) => {
				//console.log(item);
				//count += 1;
				count = item.pmareaid;
				/* empty['ok' + count] = 0; */

				severities.forEach((severity) => {
					empty[severity.acronym + count] = 0;
				});
				//empty['WARN' + count] = 0;
				//empty['ALARM' + count] = 0;
				empty['name' + count] = item.name;
				empty['pmareaid' + count] = item.pmareaid;
			});

			console.log('notif kpis area:', empty);

			const histogram = this.$store.getters.statusHistogramNotifications_byArea;
			return this.layerNotificationsActive ? { ...empty, ...histogram } : empty;
		},

		notificationsKpis_byDatasourcetype() {
			var datasourcetypes = store.getters.getDatasourcetype;
			let severities = store.getters.getSeverities;
			//console.log(areas);
			console.log('KPIS MIXIN DATASOURCETYPE');

			const empty = {};
			var count = 0;

			datasourcetypes.forEach((item) => {
				//console.log(item);
				//count += 1;
				count = item.pmdatasourcetypeid;
				/* empty['ok' + count] = 0; */
				severities.forEach((severity) => {
					empty[severity.acronym + count] = 0;
				});
				//empty['WARN' + count] = 0;
				//empty['ALARM' + count] = 0;
				empty['name' + count] = item.name;
				empty['pmdatasourcetypeid' + count] = item.pmdatasourcetypeid;
			});

			console.log('notif kpis dts:', empty);

			const histogram = this.$store.getters.statusHistogramNotifications_byDatasourcetype;
			return this.layerNotificationsActive ? { ...empty, ...histogram } : empty;
		}
	}
};
