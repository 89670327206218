<template>
	<div class="height-100">
		<div class="notifications-config" ref="notifications-config" id="notifications-config">
			<v-container class="white pb-1">
				<v-row no-gutters justify="end">
					<v-col cols="auto">
						<v-tooltip left :color="primaryColor" transition="none">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									@click="showHistoricalNotifications"
									depressed
									small
									class="btn ma-0 ml-1 fs-regular color-white"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon size="18" :ripple="false">fak fa-line-past</v-icon>
								</v-btn>
							</template>
							<span class="fs-regular">{{ this.$t('panels.notification.historic') }}</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-container>
			<historic-notifications :dialog.sync="visible" @update:dialog="visible = false" />
			<!-- <v-divider></v-divider> -->
			<v-tabs v-model="tab">
				<v-tab>{{ $t('panels.common.ByArea') }} </v-tab>
				<v-tab> {{ $t('panels.common.ByDatasourcetype') }} </v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<panel-indicadores-area
						ref="panel-indicadores-area"
						:title="$t('panels.common.notificationsbyArea')"
						type="notificationsbyArea"
						:viewport="viewport_byArea"
						:global="global_byArea"
						:filter-panel.sync="$store.state.mappanel.filterPanel.notifications"
						:active="layerNotificationsActive"
						:filtered="isLayerFiltered"
						:features="features"
						:switchoff-text="$t('panels.notifications.switchoffNotifications')"
					>
						<!-- @scrolled-end="scrolledEnd_byArea" -->
						<!--<template #grid-dialog>
						<notifications-grid ref="NotificationsGrid" />
					</template>-->
						<template #kpi-panel>
							<notifications-kpi-panel-area ref="kpiPanelArea" />
						</template>
					</panel-indicadores-area>
				</v-tab-item>
				<v-tab-item>
					<panel-indicadores-datasourcetype
						ref="panel-indicadores-datasourcetype"
						:title="$t('panels.common.notificationsbyDataSourcetype')"
						type="notificationsbyDataSourcetype"
						:viewport="viewport_byDatasourcetype"
						:global="global_byDatasourcetype"
						:filter-panel.sync="$store.state.mappanel.filterPanel.notifications"
						:active="layerNotificationsActive"
						:filtered="isLayerFiltered"
						:features="features"
						:switchoff-text="$t('panels.notifications.switchoffNotifications')"
					>
						<!-- @scrolled-end="scrolledEnd_byDatasourcetype" -->
						<!--<template #grid-dialog>
						<notifications-grid ref="NotificationsGrid" />
					</template>-->
						<template #kpi-panel>
							<notifications-kpi-panel-datasourcetype ref="kpiPanelDatasourcetype" />
						</template>
					</panel-indicadores-datasourcetype>
				</v-tab-item>
			</v-tabs-items>
		</div>
		<v-divider></v-divider>
		<panel-notifications-detail
			ref="panelnotifications"
			:title="$t('common.notificationsdetail')"
			type="notificationsdetail"
			:viewport="viewport_byArea"
			:global="global_byArea"
			:filter-panel.sync="$store.state.mappanel.filterPanel.notifications"
			:active="layerNotificationsActive"
			:filtered="isLayerFiltered"
			:features="features"
			:switchoff-text="$t('panels.notifications.switchoffNotifications')"
			:no-data-text="$t('panels.notifications.noDataNotifications')"
		>
			<!-- @scrolled-end="scrolledEnd_byArea" -->
			<template #card="{ feature }">
				<notifications-card :feature="feature.properties" />
			</template>
		</panel-notifications-detail>
	</div>
</template>

<script>
//import Panel from '../Panel';
import PanelNotificationsDetail from '../PanelNotificationsDetail';
import PanelIndicadoresArea from '../PanelKPIsDetailArea';
import PanelIndicadoresDatasourcetype from '../PanelKPIsDetailDatasourcetype';

import NotificationsKpiPanelArea from './NotificationsKpiPanelArea';
import NotificationsKpiPanelDatasourcetype from './NotificationsKpiPanelDatasourcetype';
//import NotificationsKpiPanel from './NotificationsKpiPanel';

import NotificationsCard from './NotificationsCard';

import ListScrollMixin from '@/mixins/ListScrollMixin';
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';
import LayerFilteredMixin from '@/mixins/LayerFilteredMixin';

import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import LayersActiveMixin from '@/mixins/LayersActiveMixin';

import HistoricNotifications from '@/components/map/sections/info/notifications/historical/HistoricNotifications';
import { mapState } from 'vuex'; // Import Vuex mapState
//import debounce from 'debounce';
import { adjustNotificationsHeight } from '@/api/common';
import _debounce from 'debounce';

export default {
	name: 'NotificationsMapPanelGrouped',

	mixins: [QueriesSQLMixin, ListScrollMixin, LayersActiveMixin, LayerFilteredMixin],

	components: {
		//Panel,
		PanelNotificationsDetail,
		PanelIndicadoresArea,
		PanelIndicadoresDatasourcetype,
		NotificationsKpiPanelArea,
		NotificationsKpiPanelDatasourcetype,
		//NotificationsKpiPanel,
		NotificationsCard,
		HistoricNotifications
	},

	data() {
		return {
			tab: null,
			visible: false,
			panelHeight: null,
			primaryColor: this.$store.state.global.primaryColor + 'cc'
		};
	},

	computed: {
		/* offset: {
			get() {
				return this.$store.getters.notificationsOffset;
			},
			set(offset) {
				this.$store.commit('setOffset', { identifier: NOTIFICATIONS_LAYER, offset });
			}
		}, */
		...mapState({
			storeData: (state) => state.data[NOTIFICATIONS_LAYER].viewportFeatures
		}),

		limit() {
			return this.$store.getters.notificationsLimit;
		},

		features: {
			get() {
				return this.layerNotificationsActive ? this.$store.getters.featuresNotifications : [];
			},
			set(identifier, data) {
				this.$store.dispatch('setFeatures', { identifier, data });
			}
		},

		viewport_byArea() {
			return this.layerNotificationsActive ? this.$store.getters.totalNotifications_byArea.viewport : 0;
		},

		global_byArea() {
			return this.layerNotificationsActive ? this.$store.getters.totalNotifications_byArea.global : 0;
		},

		viewport_byDatasourcetype() {
			return this.layerNotificationsActive ? this.$store.getters.totalNotifications_byDatasourcetype.viewport : 0;
		},

		global_byDatasourcetype() {
			return this.layerNotificationsActive ? this.$store.getters.totalNotifications_byDatasourcetype.global : 0;
		}
	},
	watch: {
		/* offset: 'scrollTop', */
		storeData(newValue, oldValue) {
			// This watcher will be triggered whenever storeData changes.
			// You can perform actions or trigger a component reload here.
			if (newValue !== oldValue) {
				// Perform actions or reload the component as needed
				console.log('dev hlp viewport changes detected!', newValue, oldValue);
				// For example, you can use this.$forceUpdate() to force a component update
				//this.$forceUpdate();
			}
		},
		tab(newValue, oldValue) {
			console.log('info notif: tab', oldValue, newValue);
			console.log('tab state watcher', this.$store.state.data[NOTIFICATIONS_LAYER].viewportFeatures);
			_debounce(adjustNotificationsHeight(this.$store.state.data), 300);
		}
	},
	created() {},
	mounted() {
		console.log('dev hlp notif', this.features, this.global_byArea, this.viewport_byArea);
		if (this.$refs['notifications-config']) {
			document.documentElement.style.setProperty(
				'--notifications-config-height',
				document.getElementsByClassName('notifications-config')[0].clientHeight + 'px'
			);
		}
		document.addEventListener('keydown', this.hideDialog);
		console.log(
			'notifgrouped',
			this.storeData,
			this.$refs['notifications-config'],
			document.documentElement.style.getPropertyValue('--notifications-config-height')
		);
		console.debug(
			'info notif mounted: ',
			document.getElementById('notification-panel-config-area'),
			document.getElementById('notification-panel-config-datasource'),
			document.getElementById('notification-panel-config')
		);
	},
	updated() {
		if (document.getElementsByClassName('notifications-config')[0]) {
			document.documentElement.style.setProperty(
				'--notifications-config-height',
				document.getElementsByClassName('notifications-config')[0].clientHeight + 'px'
			);
		}
		console.debug(
			'info notif area: ',
			document.getElementById('notification-panel-config-area'),
			document.getElementById('notification-panel-config-datasource'),
			document.getElementById('notification-panel-config')
		);
		console.log('updated notifications', this.storeData, document.documentElement.style.getPropertyValue('--notifications-config-height'));
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.hideDialog);
	},

	methods: {
		/**
		 * Handle list scroll (Methods used by mixin)
		 */
		onMapMoveend() {
			this.offset = 0;
		},

		/* scrolledEnd_byArea() {
			const total = this.viewport_byArea;

			if (this.loading || this.offset + this.limit >= total) {
				return;
			}

			this.offset += this.limit;
		},

		scrolledEnd_byDatasourcetype() {
			const total = this.viewport_byDatasourcetype;

			if (this.loading || this.offset + this.limit >= total) {
				return;
			}

			this.offset += this.limit;
		},

		scrollTop() {
			if (this.offset !== 0 || !this.$refs.panelnotifications) {
				return;
			}
			console.log('dev help scrollTop', this.$refs.panelnotifications);
			this.$refs.panelnotifications.scrollTop();
		}, */
		showHistoricalNotifications() {
			this.visible = true;
		},
		hideDialog(e) {
			//console.log('hideDialog', e.key, e.key == 'Escape');
			if (e.key == 'Escape' && this.visible) this.visible = false;
		}
	}
};
</script>

<style lang="postcss">
.separatorpanel {
	background: #cccdd3;
	height: 5px;
}
.btn {
	border-radius: 2px;
	background-color: var(--azul-primary-100) !important;
	min-width: 25px;
}
</style>
