import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import { lastData } from '@/api/databases_API';
import { query_Document } from '@/utils/queries';
// Queries
//import { search } from '@/api/common';

export function getPanelOptions(identifier, feature) {
	const coordinates = generateCoordinates(feature);
	const pk = generatePk(identifier, feature);
	const panelId = generatePanelId(pk);
	const elasticData = feature['is_average'] == 0 ? getElasticData(feature) : {};
	return { identifier, panelId, pk, coordinates, data: feature, elastic: elasticData };
}

export function generateCoordinates({ longitude, latitude }) {
	return longitude !== null && latitude !== null ? [longitude, latitude] : null;
}

export function generatePk(identifier, data) {
	const { pm_notifications_id } = data;

	switch (identifier) {
		case NOTIFICATIONS_LAYER:
			return { identifier, pm_notifications_id };
		default:
			return null;
	}
}

export function generatePanelId(pk) {
	return Object.values(pk).join('::');
}

export async function getElasticData(feature) {
	console.log('elastic data 1', feature['elastic_index'], feature['original_elastic_id']);
	/* getElasticDocument(feature['elastic_index'], feature['original_elastic_id']).then((data) => {
		console.log('elastic data 2', data, data['message']['_source'])
		return data['message']['_source']
	}) */

	const data = await lastData(feature['elastic_index'], query_Document(feature['original_elastic_id']));

	return data['message'][0]['_source'];
	/* const filterByID = {
		groups: [],
		groupOp: 'and',
		rules: [{ field: 'originalelasticid', op: 'eq', data: feature['original_elastic_id'] }]
	};
	//contains cn

	const body = {
		model: 'bpahistoricalnotifications',
		filter: filterByID,
		rows: 1
	};

	const { data } = await search(body);

	console.log('RESULT (Elastic data)');
	var elasticData = data.data[0];
	console.log(elasticData);

	return elasticData; */
}
