<template>
	<div class="panel-notifications-detail panel overflow-scroll">
		<!-- Header List Card -->
		<div class="list-panel-title fs-md fw-bold color-black py-3 mx-3">{{ detailTitle }}</div>
		<v-card flat class="mx-3 mb-1 rounded">
			<v-text-field
				v-if="(featuresNotifications && featuresNotifications.length > notificationThreshold) || filterNotifications"
				class="ma-0 pa-0 border"
				v-model="filterNotifications"
				hide-details
				:placeholder="$t('panels.notifications.searchByCode')"
				:title="$t('panels.notifications.searchByCode')"
				append-icon="fak fa-line-search"
				single-line
				solo
				dense
				flat
				outlined
				maxlength="25"
				clearable
			></v-text-field>
		</v-card>
		<!-- List -->
		<!-- style="overflow-y: auto" ref="scrollElement" -->
		<div v-if="(!featuresNotifications || featuresNotifications.length == 0) && !notificationsLayer">
			<!-- No notification data. An advice is showed in the panel-->
			<div class="switchoff d-flex flex-column align-center justify-center mt-3">
				<v-icon size="1.5rem"> fal fa-inbox </v-icon>

				<div class="switchoff-text">{{ noDataText }}</div>
			</div>
		</div>
		<div class="list-panel d-flex flex-column" id="panel-notifications-detail" v-else>
			<div v-for="(feature, index) in featuresNotifications" :key="index" class="mx-3">
				<slot name="card" v-bind="{ feature }" />
			</div>

			<!-- Intersection observer -->
			<!-- <div class="py-2" v-intersect="() => $emit('scrolled-end')"></div> -->

			<!-- Layer disabled. If the layer is disabled an advice is showed in the panel-->
			<div class="switchoff d-flex flex-column align-center justify-center mt-3" v-if="!active">
				<v-icon size="2rem"> fal fa-toggle-off </v-icon>

				<div class="switchoff-text">{{ switchoffText }}</div>
			</div>
		</div>
	</div>
</template>
<script>
import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';

import constants from '@/utils/constants';

export default {
	name: 'PanelNotificationsDetail',

	components: {},

	props: {
		title: {
			type: String,
			required: true
		},

		type: {
			type: String
		},
		/**
		 * Viewport count
		 */
		viewport: {
			type: Number,
			required: true
		},

		/**
		 * Global count
		 */
		global: {
			type: Number,
			required: true
		},

		/**
		 * Opened filter panel
		 */
		filterPanel: {
			type: Boolean,
			required: true
		},

		/**
		 * Layer active
		 */
		active: {
			type: Boolean,
			required: true
		},

		filtered: {
			type: Boolean,
			required: true
		},

		features: {
			type: Array,
			required: true
		},

		switchoffText: {
			type: String,
			required: true
		},
		noDataText: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			filterNotifications: null,
			notificationThreshold: constants.NOTIFICATION_PANEL_THRESHOLD
		};
	},
	computed: {
		detailTitle() {
			if (this.type === 'notificationsdetail') {
				return this.$t('panels.notifications.detailnotifications');
			} else {
				return 'TEST';
			}
		},
		notificationsLayer() {
			return this.$store.getters.notificationsLayer(this.userProperties.organizationid);
		},
		featuresNotifications() {
			if (this.filterNotifications) {
				return this.$store.state.data[NOTIFICATIONS_LAYER].viewportFeatures.filter(
					(notification) => !!notification.properties['station_code'].toLowerCase().includes(this.filterNotifications.toLowerCase())
				);
			}
			return this.$store.state.data[NOTIFICATIONS_LAYER].viewportFeatures;
		}
	},
	watch: {},
	mounted() {
		console.log(
			'panel notifications features',
			this.notificationsLayer,
			this.featuresNotifications,
			this.$store.state.data.notifications,
			this.$store.state.data[NOTIFICATIONS_LAYER].viewportFeatures
		);

		//console.warn(this.features);
		//console.log( this.$store.state.data[NOTIFICATIONS_LAYER].viewportFeatures);
		//console.log( this.$store.state.data[NOTIFICATIONS_LAYER]);
	},
	updated() {
		console.debug(
			'info notif: ',
			document.getElementById('notification-panel-config-area'),
			document.getElementById('notification-panel-config-datasource'),
			document.getElementById('notification-panel-config')
		);
	},
	methods: {
		/*scrollTop() {
			this.$refs.scrollElement.scrollTop = 0;
		}*/
	}
};
</script>

<style lang="postcss">
@import '../../../../styles/eiffel-variables.pcss';
.panel {
	//height: calc(100% - var(--notifications-config-height) - 100px);
	& .first-line {
		& .first-line--title {
			font-style: normal;
			font-stretch: normal;
			line-height: 1.2;
			letter-spacing: normal;
		}
	}

	& .second-line {
		& .figures {
			font-style: italic;
			font-size: 1rem;
			color: var(--gris-300);
		}

		& .big-figure {
			font-style: normal;
			font-size: 1.5rem;
		}

		& .total-figure {
			padding-left: 2px;
		}
	}

	& .filters-panel {
		background-color: var(--low);
	}

	& .list-panel-title {
		font-size: 1.1rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
	}

	& .list-panel {
		overflow-y: scroll;
		//max-height: calc(75vh - 195px);
		max-height: calc(100vh - var(--notification-panel-diff));
		& .switchoff {
			color: var(--gris-300);
			& .switchoff_icon {
				font-size: 2rem;
			}
			& .switchoff-text {
				font-size: 1rem;
			}
		}
	}
}
</style>
