import ol2map from '@/components/map/sections/map/subcomponents/ol2map';

export default {
	computed: {
		loading() {
			return this.$store.getters.loading;
		},

		map() {
			return ol2map.getMapInstance();
		}
	},

	methods: {
		onMapChange() {
			if (this.map) {
				this.map.on('moveend', this.onMapMoveend.bind(this));
				this.map.on('movestart', this.onMapMovestart.bind(this));
			}
			this.$puiEvents.$on('map:onViewStateChange', this.onMapMoveend.bind(this));
			this.$puiEvents.$on('map:onViewStateChange', this.onMapMovestart.bind(this));
		},

		onMapMovestart() {
			const element = this.$refs.scrollElement;

			if (element) {
				element.scrollTop = 0;
			}
		}
	},

	destroyed() {
		if (this.map) {
			this.map.un('moveend', this.onMapMoveend);
			this.map.un('movestart', this.onMapMovestart);
		}
		this.$puiEvents.$on('map:onViewStateChange', this.onMapMoveend.bind(this));
		this.$puiEvents.$on('map:onViewStateChange', this.onMapMovestart.bind(this));
	},

	watch: {
		map: 'onMapChange'
	}
};
