<template>
	<!-- First Line -->
	<div class="panel pa-1" id="notification-panel-config-datasource">
		<div class="d-flex align-center flex-no-wrap first-line px-1 pt-1">
			<div class="first-line--title fs-md fw-bold">{{ title }}</div>
		</div>

		<!-- Second Line -->
		<div class="second-line d-flex align-baseline px-2">
			<div class="figures" style="max-width: 70%; white-space: nowrap; word-break: break-word">
				<span class="big-figure pr-1">{{ viewport }}</span>
				<span class="pr-1">{{ $t('common.of') }}</span>
				<span class="total-figure">{{ global }}</span>
			</div>
		</div>

		<!-- Kpis -->
		<div class="pt-1 px-3 kpipanel" style="max-height: 30vh !important; overflow-y: scroll">
			<slot name="kpi-panel" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'PanelKpisDetailDatasourcetype',

	components: {},

	props: {
		title: {
			type: String,
			required: true
		},

		type: {
			type: String
		},
		/**
		 * Viewport count
		 */
		viewport: {
			type: Number,
			required: true
		},

		/**
		 * Global count
		 */
		global: {
			type: Number,
			required: true
		},

		/**
		 * Opened filter panel
		 */
		filterPanel: {
			type: Boolean,
			required: true
		},

		/**
		 * Layer active
		 */
		active: {
			type: Boolean,
			required: true
		},

		filtered: {
			type: Boolean,
			required: true
		},

		features: {
			type: Array,
			required: true
		},

		switchoffText: {
			type: String,
			required: true
		}
	},

	computed: {},
	mounted() {},
	methods: {
		scrollTop() {
			this.$refs.scrollElement.scrollTop = 0;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../styles/eiffel-variables.pcss';
.panel {
	& .first-line {
		& .first-line--title {
			font-style: normal;
			font-stretch: normal;
			line-height: 1.2;
			letter-spacing: normal;
		}
	}

	& .second-line {
		& .figures {
			font-style: italic;
			font-size: 1rem;
			color: var(--gris-300);
		}

		& .big-figure {
			font-style: normal;
			font-size: 1.5rem;
		}

		& .total-figure {
			padding-left: 2px;
		}
	}

	& .filters-panel {
		background-color: var(--low);
	}

	& .list-panel-title {
		font-size: 1.1rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
	}

	& .list-panel {
		& .switchoff {
			color: var(--gris-300);
			& .switchoff_icon {
				font-size: 2rem;
			}
			& .switchoff-text {
				font-size: 1rem;
			}
		}
	}
}
</style>
