<template>
	<div class="detailline d-flex align-center" @click="$listeners.click" :class="{ disabled: disabled }">
		<div class="pointIcon" :style="'background: ' + colorClass"></div>
		<!-- :class="{ [colorClass]: true }" -->
		<div class="pl-4">{{ title }}</div>

		<div class="warningIcon ml-auto">
			<i class="fas fa-exclamation-triangle" v-show="showExclamation"></i>
		</div>

		<div class="figure pl-2">{{ total }}</div>
	</div>
</template>

<script>
export default {
	props: {
		disabled: {
			type: Boolean
		},

		showExclamation: {
			type: Boolean,
			default: false
		},

		colorClass: {
			type: String
		},

		title: {
			type: String
		},

		total: {
			type: [String, Number]
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.detailline {
	font-size: 12px;
	font-weight: normal;

	color: var(--high);
	cursor: pointer;

	& * {
		transition: color 0.2s ease-in;
	}

	&.disabled {
		color: var(--moderate);
		cursor: auto;

		& .warningIcon {
			color: var(--moderate);
			text-align: right;
		}
		& .pointIcon {
			background: var(--moderate);
			background-color: var(--moderate) !important;
			border-color: var(--moderate) !important;
		}
	}

	& .warningIcon {
		color: var(--warning);
		text-align: right;
	}
	& .pointIcon {
		width: 6px;
		height: 6px;

		background-color: #e3e3e3;
		border-radius: 4px;

		&.green {
			background: var(--status-ok);
		}
		&.orange {
			background: var(--status-warning);
		}
		&.red {
			background: var(--status-alerm);
		}
	}
}
</style>
