<template>
	<div class="header d-flex flex-column ml-4 justify-center">
		<div class="d-flex mt-2 justify-start" :class="{ disabled }" @click="centerAreaMap(areaId)" style="width: 100% !important">
			<div>{{ title }}</div>

			<v-icon size="18" class="ml-2" @click="$listeners['click:close']" v-if="showClose">close</v-icon>

			<div class="ml-auto">{{ total }}</div>
		</div>

		<div class="kpibar d-flex justify-start">
			<slot />
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import * as olProj from 'ol/proj';
import * as olExtent from 'ol/extent';

export default {
	props: {
		disabled: {
			type: Boolean
		},

		showClose: {
			type: Boolean,
			default: false
		},

		title: {
			type: String
		},
		areaId: {
			type: String
		},

		total: {
			type: Number
		}
	},
	methods: {
		centerAreaMap(areaId) {
			let areas = this.$store.getters.getAreas;
			let userProps = this.userProperties;
			let selectedArea = areas.find((area) => area.pmareaid == areaId);
			let polygon = [];
			// TODO: use areapoints array to center on area. example: [[lat,lon],[lat,lon]]
			console.log('NEW Structure: Area points', JSON.parse(selectedArea.areapoints), selectedArea);
			if (areaId != this.selectedAreaId) {
				if (selectedArea && selectedArea.nelat) {
					polygon = [
						[selectedArea.nelat, selectedArea.nelon],
						[selectedArea.swlat, selectedArea.nelon],
						[selectedArea.swlat, selectedArea.swlon],
						[selectedArea.nelat, selectedArea.swlon]
					];
				} else {
					polygon = [
						[userProps.nelat, userProps.nelon],
						[userProps.swlat, userProps.nelon],
						[userProps.swlat, userProps.swlon],
						[userProps.nelat, userProps.swlon]
					];
					this.$puiNotify.warning(this.$t('map.areaNotFound'), this.$t('status.warning'));
				}
				this.selectedAreaId = areaId;
			} else {
				polygon = [
					[userProps.nelat, userProps.nelon],
					[userProps.swlat, userProps.nelon],
					[userProps.swlat, userProps.swlon],
					[userProps.nelat, userProps.swlon]
				];
				this.$puiNotify.info(this.$t('map.zoomReset'), this.$t('status.info'));
				this.selectedAreaId = null;
			}

			console.log('selected area header', areaId, this.selectedAreaId, selectedArea);

			ol2map.zoomToExtent(olProj.transformExtent(new olExtent.boundingExtent(polygon), 'EPSG:4326', 'EPSG:3857'));
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.header {
	color: var(--high);

	& * {
		transition: color 0.2s ease-in;
	}

	& .disabled {
		* {
			color: var(--moderate);
		}
	}
}

.kpibar {
	height: 6px;
	width: 100%;
	background: #e4e4e4;
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}
</style>
